/** MUI */
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    srOnly: {
        position: "absolute",
        width: 1,
        height: 1,
        padding: 0,
        margin: -1,
        overflow: "hidden",
        clip: "rect(0, 0, 0, 0)",
        border: 0,
    },
}));

const SronlyH2 = (props) => {
    const { children, ...rest } = props;
    const classes = useStyles();
    return (
        <h2 className={classes.srOnly} {...rest}>
            {children}
        </h2>
    );
};

export default SronlyH2;
