/** MUI */
import {alpha, Container, Grid, Skeleton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import WidgetSkeleton from "./WidgetSkeleton";

const useStyles = makeStyles((theme) => ({
    skeleton: {
        backgroundColor: alpha(theme.palette.primary.light, 0.25),
    },
}));

const TabButton = () => {
    const classes=useStyles()
    return <Skeleton animation="wave" height={36} width="100%" className={classes.skeleton}/>;
};

const AreasPresentationSkeleton = () => {
    return (
        <Container maxWidth={false}>
            <Container maxWidth={"xl"} sx={{ my: 5 }} style={{ maxWidth: 1270 }}>
                <Grid container spacing={15}>
                    {[1,2,3,4]
                        .map((w) => {
                            return (
                                <Grid item xs={12} lg={3} key={w}>
                                    <TabButton />
                                </Grid>
                            );
                        })}
                </Grid>
            </Container>

            <Container maxWidth={false}>
                <Grid container spacing={5}>
                    {[1,2,3,4]
                        .map((w) => {
                            return (
                                <Grid item xs={12} lg={3} key={w}>
                                    <WidgetSkeleton />
                                </Grid>
                            );
                        })}
                </Grid>
            </Container>
        </Container>
    );
};

export default AreasPresentationSkeleton;
