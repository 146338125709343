import React, { useEffect, useState } from "react";

/** Helpers */
import { useTranslation } from "react-i18next";
import useHelper from "../../../_common/useHelper";

/** Nextjs */
import dynamic from "next/dynamic";

/** MUI */
import { Alert, Box, Container, Grid, Tab, Tabs, Select, MenuItem } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
/** - icons */
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

/** API */
import { getHomepageAreasGroupedFromAPI } from "../../../_APIs";

/** UI */
import AreasPresentationSkeleton from "../skeletons/AreasPresentationSkeleton";
import WidgetSkeleton from "../skeletons/WidgetSkeleton";
// const Preloader = dynamic(() => import("../../../_common/Preloader"));
const WidgetCard = dynamic(() => import("./components/WidgetCard"), {
    loading: () => <WidgetSkeleton />,
});

const SelectIcon = () => {
    return <ArrowDropDownIcon color={"primary"} />;
};

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`group-tabpanel-${index}`}
            aria-labelledby={`group-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
        </div>
    );
};

const a11yProps = (index) => {
    return {
        id: `group-tab-${index}`,
        value: index,
        "aria-controls": `group-tabpanel-${index}`,
    };
};

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(1, 5, 1, 5),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1, 2, 1, 2),
        },
        "&>div": {},
        "& > $widgetGridItem": {
            maxWidth: "calc(25% - 30px)",
        },
    },
    link: {
        cursor: "pointer",
    },
    areaTitle: {
        fontSize: "1.77777778rem",
        fontWeight: 500,
        textAlign: "center",
        margin: theme.spacing(7, 0, 7, 0),
    },
    chartHolder: {
        ...theme.shape.boxedWrapper,
        height: "100%",
        margin: theme.spacing(1),
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    cardTitle: {
        overflow: "hidden",
        padding: theme.spacing(0),
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        fontSize: "1rem",
        [theme.breakpoints.down("lg")]: {
            fontSize: "0.777777778rem",
        },
        [theme.breakpoints.down("md")]: {
            fontSize: "0.888888889rem",
        },
    },
    cardChart: {
        height: 228,
        paddingTop: 0,
        width: "100%",
    },
    moreHolder: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: theme.spacing(3, 0, 0, 0),
        margin: theme.spacing(4, 0, 7, 0),
    },
    odd: {
        backgroundColor: "#FFF",
    },
    even: {
        backgroundColor: "transparent",
    },
    showMoreHolder: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: theme.spacing(3),
        paddingTop: 0,
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(4),
    },
    widgetGridItem: {
        display: "flex",
        justifyContent: "center",
        "@media (min-width: 1920px) and (max-width: 1920px)": {
            minHeight: 500,
        },
        [theme.breakpoints.between("sm", "lg")]: {
            "&:nth-child(odd)": {
                justifyContent: "flex-end",
            },
            "&:nth-child(even)": {
                justifyContent: "flex-start",
            },
        },
    },
    tabsRoot: {},
    tabRoot: {
        color: theme.palette.primary.main,
        fontSize: "1rem",
        fontWeight: 600,
        padding: theme.spacing(2.5, 0, 2.375),
        letterSpacing: "0.0694444444rem",
        [theme.breakpoints.up("lg")]: {},
        [theme.breakpoints.down("md")]: {
            alignItems: "center",
            fontSize: "0.88888889rem",
        },
    },
    flexContainer: {
        gap: theme.spacing(4),
        [theme.breakpoints.down("md")]: {
            gap: theme.spacing(0),
        },
    },
    scrollButtons: {
        [theme.breakpoints.down("sm")]: {
            color: theme.palette.primary.main,
            borderRadius: "50%",
            height: 40,
        },
    },
    TabBtn: {},
    mobileTabsWrap: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0, 1, 0, 3),
    },
    mobileSelect: {
        "& fieldset": {
            borderColor: "transparent!important",
        },
    },
    mobileSelectOutlined: {
        color: theme.palette.primary.main,
        fontSize: "1.11111111rem",
        fontWeight: 600,
        padding: "8px 8px!important",
        letterSpacing: "0.0694444444rem",
        textAlign: "center",
        textTransform: "uppercase",
        borderBottom: `2px solid ${theme.palette.primary.main}`,
        borderRadius: "0!important",
    },
    mobileSelectMenuItem: {
        color: theme.palette.primary.main,
        fontSize: "1rem",
        fontWeight: 600,
        padding: "8px 16px!important",
        letterSpacing: "0.0694444444rem",
        textAlign: "center",
        justifyContent: "center",
        textTransform: "uppercase",
    },
    mobileSelectMenuPaper: {
        left: "8px!important",
        minWidth: "calc(100vw - 16px)!important",
        borderTop: `2px solid ${theme.palette.primary.main}`,
        borderRadius: "0 0 4px 4px",
        marginTop: -2,
    },
}));

const AreasPresentation = () => {
    const { t } = useTranslation();
    const { translationFromJson } = useHelper();
    const classes = useStyles();

    /** States */
    const [AREAS, setAREAS] = useState(null);
    const [ERROR, setERROR] = useState(false);
    const [GROUP, setGROUP] = useState(null);
    const [LOADING, setLOADING] = useState(false);
    const [IS_MOBILE, setIS_MOBILE] = useState(false);

    /** Effects */
    /** - get data from API */
    useEffect(() => {
        /** clean up controller */
        let isSubscribed = true;

        isSubscribed && setLOADING(true);
        getHomepageAreasGroupedFromAPI()
            .then((result) => {
                if (result.message && result.message.type === "Error") {
                    isSubscribed && setERROR(result);
                } else {
                    isSubscribed && setAREAS(result);
                }
            })
            .catch((error) => console.log(error))
            .finally(() => {
                // isSubscribed &&
                //     setTimeout(() => {
                //         setLOADING(false);
                //     }, 5000);
                isSubscribed && setLOADING(false);
            });

        /** cancel subscription to useEffect */
        return () => (isSubscribed = false);
    }, []);

    /** - set GROUP */
    useEffect(() => {
        /** clean up controller */
        let isSubscribed = true;

        if (!AREAS) return;
        const _group = AREAS.groups[0]?.id !== undefined ? AREAS.groups[0]?.id : null;
        // isSubscribed && setGROUP(AREAS.groups[0]?.id !== undefined ? AREAS.groups[0]?.id : null);
        isSubscribed && setGROUP(_group);

        /** cancel subscription to useEffect */
        return () => (isSubscribed = false);
    }, [AREAS]);

    /** - set IS_MOBILE */
    useEffect(() => {
        /** clean up controller */
        let isSubscribed = true;

        const isMobile = () => {
            isSubscribed && setIS_MOBILE(window?.innerWidth < 599.99);
        };
        isMobile();
        window.addEventListener("resize", isMobile);
        return () => {
            window.removeEventListener("resize", isMobile);
            isSubscribed = false;
        };
    }, []);

    /** Handlers */
    const handleChangeGroup = (event, newValue) => {
        setGROUP(newValue);
    };
    const handleChangeGroupMobile = (event) => {
        const _newValue = event.target.value;
        setGROUP(_newValue);
    };

    return (
        <>
            {!!ERROR && (
                <Container maxWidth={"md"}>
                    <Alert severity={"error"} sx={{ width: "100%" }} variant={"outlined"}>
                        {translationFromJson(ERROR?.message, "text") || t("Nieznany błąd")}
                    </Alert>
                </Container>
            )}
            {/* {!!LOADING && (
                <Container maxWidth={"md"}>
                    <Preloader type={"linear"} />
                </Container>
            )} */}

            {!!AREAS && GROUP !== null && !LOADING && !ERROR ? (
                <>
                    <Container maxWidth={"lg"} style={{ maxWidth: 1270 }} sx={{ px: { xs: 0, md: 2 } }}>
                        <Box sx={{ width: "100%", bgcolor: "background.paper", pt: 1.75, pb: 6 }}>
                            {IS_MOBILE ? (
                                <Box className={classes.mobileTabsWrap}>
                                    <Select
                                        // id="demo-simple-select"
                                        value={GROUP}
                                        // label="Age"
                                        onChange={handleChangeGroupMobile}
                                        fullWidth
                                        IconComponent={SelectIcon}
                                        classes={{ select: classes.mobileSelectOutlined }}
                                        className={classes.mobileSelect}
                                        MenuProps={{
                                            // anchorOrigin: {
                                            //     vertical: "bottom",
                                            //     horizontal: "right",
                                            // },
                                            // transformOrigin: {
                                            //     vertical: "top",
                                            //     horizontal: "right",
                                            // },
                                            classes: { paper: classes.mobileSelectMenuPaper },
                                        }}
                                    >
                                        {AREAS?.groups?.map((group) => {
                                            return (
                                                <MenuItem
                                                    key={group.id}
                                                    value={group.id}
                                                    classes={{ root: classes.mobileSelectMenuItem }}
                                                    dense
                                                >
                                                    {translationFromJson(group, "name")}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </Box>
                            ) : (
                                <Tabs
                                    value={GROUP}
                                    onChange={handleChangeGroup}
                                    centered={!IS_MOBILE}
                                    variant={IS_MOBILE ? "scrollable" : "fullWidth"}
                                    scrollButtons
                                    allowScrollButtonsMobile
                                    textColor="primary"
                                    indicatorColor="primary"
                                    classes={{
                                        // root: classes.tabsRoot,
                                        flexContainer: classes.flexContainer,
                                        scrollButtons: classes.scrollButtons,
                                    }}
                                    aria-label={t("Prezentacja wskaźników według grup i obszarów")}
                                    // role="navigation"
                                    id={"home_areas_prsentation"}
                                    tabIndex={-1}
                                >
                                    {AREAS?.groups?.map((group) => {
                                        return (
                                            <Tab
                                                label={translationFromJson(group, "name")}
                                                key={group.id}
                                                {...a11yProps(group.id)}
                                                classes={{ root: classes.tabRoot }}
                                            />
                                        );
                                    })}
                                </Tabs>
                            )}
                        </Box>
                    </Container>
                    <Container maxWidth={false} sx={{ px: { xs: 1, sm: 1, md: 5 } }}>
                        {AREAS?.groups?.map((group) => {
                            return (
                                <TabPanel value={GROUP} index={group.id} key={group.id}>
                                    <Grid container spacing={5}>
                                        {AREAS?.widgets
                                            ?.filter((widget) => widget.groups?.includes(group.id))
                                            ?.map((widget, i) => {
                                                return (
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={6}
                                                        md={6}
                                                        lg={3}
                                                        xl={3}
                                                        key={`${widget.id}-${i}`}
                                                        className={classes.widgetGridItem}
                                                    >
                                                        <WidgetCard area={{}} widget={widget} />
                                                    </Grid>
                                                );
                                            })}
                                    </Grid>
                                </TabPanel>
                            );
                        })}
                    </Container>
                </>
            ) : (
                // <Container maxWidth={"md"}>
                //     <Preloader type={"linear"} />
                // </Container>
                <AreasPresentationSkeleton />
            )}
        </>
    );
};

export default AreasPresentation;
