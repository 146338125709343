/** MUI */
import { alpha, Skeleton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    skeleton: {
        backgroundColor: alpha(theme.palette.primary.light, 0.075),
    },
}));

const ChartSkeleton = () => {
    const classes = useStyles();
    return (
        <>
            <Skeleton
                sx={{ height: 190 }}
                width="100%"
                animation="wave"
                variant="rectangular"
                className={classes.skeleton}
            />
        </>
    );
};

export default ChartSkeleton;
