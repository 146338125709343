import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

/** Nextjs */
import dynamic from "next/dynamic";
import Head from "next/head";

/** MUI */
import { useMediaQuery, Button, Container, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
/** - icons */
import { ArrowForwardIos } from "@mui/icons-material";

/** redux */
import { useDispatch } from "react-redux";
import { setSkipLinksPage } from "@/src/_appStore/Common";

/** matomo */
import { useMatomo } from "@datapunt/matomo-tracker-react";

/** UI */
// const MainSearchbar = dynamic(() => import("../_Commons/SearchMain/MainSearchbar"));
import MainSearchbar from "../_Commons/SearchMain/MainSearchbar";

import AreasPresentationSkeleton from "./skeletons/AreasPresentationSkeleton";
// const AreasPresentation = dynamic(() => import("../HomePage/AreasPresentationTabbed/AreasPresentation"), {
//     loading: () => <AreasPresentationSkeleton />,
// });
import AreasPresentation from "../HomePage/AreasPresentationTabbed/AreasPresentation";

import SronlyH2 from "@/src/components/srOnly/SronlyH2";

const useStyles = makeStyles((theme) => ({
    rootContainer: {
        position: "relative",
        minHeight: "calc(100vh - 280px)",
        backgroundColor: "transparent",
        padding: "16px 4px 0 44px",
        transition: "padding 0.3s linear",
    },
    oldVersionBtnContainer: {
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(10),
        [theme.breakpoints.down("sm")]: {
            paddingTop: theme.spacing(3),
        },
    },
    oldVersionBtn: {
        maxHeight: 51,
        "&, &:hover, &:focus, &:active": {},
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            padding: theme.spacing(1, 1.5),
            // fontSize: "0.675rem",
            fontSize: "0.88888889rem",
        },
    },
    oldVersionBtnStartIcon: {
        marginRight: theme.spacing(0.5),
        [theme.breakpoints.down("sm")]: {
            "&, & > svg, &>*:nth-of-type(1)": {
                fontSize: "0.75rem",
            },
        },
    },
    linksWrapper: {
        paddingBottom: theme.spacing(10),
        position: "relative",
        [theme.breakpoints.between("md", "xl")]: {},
        [theme.breakpoints.down("sm")]: {},
    },
    cardHolder: {
        display: "flex",
        justifyContent: "center",
        paddingTop: "0!important",
    },
    whiteBottom: {
        position: "absolute",
        zIndex: -1,
        width: "100%",
        backgroundColor: "#fff",
        left: 0,
        bottom: 0,
        right: 0,
        top: 200,
        height: "calc(100% - 200px)",
        [theme.breakpoints.down("xl")]: {
            top: 150,
            height: "calc(100% - 150px)",
        },
        [theme.breakpoints.down("md")]: {
            height: "calc(100% - 200px)",
        },
    },
}));

const Homepage = (props) => {
    const { areas } = props;
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { trackPageView } = useMatomo();
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

    /** effects */
    useEffect(() => {
        trackPageView();
        dispatch(setSkipLinksPage({ id: "home" }));
        return () => dispatch(setSkipLinksPage(""));
    }, []);

    return (
        <>
            <SronlyH2>{t("Wyszukiwarka zasobów DBW")}</SronlyH2>
            <MainSearchbar />

            {/** Areas presentation */}
            <SronlyH2>{t("Wykresy w podziale na grupy")}</SronlyH2>
            <Container maxWidth={false} disableGutters sx={{ mt: { xs: 1, md: 0 }, backgroundColor: "#FFF" }}>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <AreasPresentation areas={areas} />
                    </Grid>
                </Grid>
            </Container>

            <Container maxWidth={false} className={classes.oldVersionBtnContainer}>
                {/* <Button
                    variant={"outlined"}
                    href={"http://swaid.stat.gov.pl/"}
                    target={"_blank"}
                    startIcon={<ArrowForwardIos sx={{ fontSize: "0.77777778rem" }} />}
                    className={classes.oldVersionBtn}
                    classes={{ startIcon: classes.oldVersionBtnStartIcon }}
                >
                    {isMobile ? t("POPRZEDNIA WERSJA DBW") : t("PRZEJDŹ DO POPRZEDNIEJ WERSJI DBW")}
                </Button> */}
            </Container>
        </>
    );
};

export default Homepage;
