import React, { useEffect } from "react";

/** Helpers */
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

/** Nextjs */
import dynamic from "next/dynamic";

/** API */
import { getHomepageAreasFromAPI } from "../src/_APIs";

/** Redux */
import { useDispatch } from "react-redux";
import { fetchAreasFromIndexedDB } from "../src/_appStore/utils/fetch";

/** UI */
// const Homepage = dynamic(() => import("../src/pages/HomePage/Homepage"));
import Homepage from "../src/pages/HomePage/Homepage";
const HomeLayout = dynamic(() => import("../src/layouts/HomeLayout/HomeLayout"));

const HomePageIndex = (props) => {
    const { areas } = props;
    const dispatch = useDispatch();

    useEffect(() => {
        Promise.all([dispatch(fetchAreasFromIndexedDB())])
            .then(() => {})
            .catch(
                (err) => process.env.NODE_ENV === "development" && console.log("%c DB: error", "background:#F00", err)
            );
    }, []);
    return (
        <>
            <HomeLayout>
                <Homepage areas={areas} />
            </HomeLayout>
        </>
    );
};

export async function getStaticProps({ locale }) {
    const areas = await getHomepageAreasFromAPI();

    return {
        props: {
            areas,
            ...(await serverSideTranslations(locale, ["common"])),
            namespacesRequired: ["common"],
        },
        revalidate: 10,
    };
}

export default HomePageIndex;
