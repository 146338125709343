// AreasPresentationSkeleton

/** MUI */
import { alpha, Card, CardHeader, CardContent, Skeleton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import ChartSkeleton from "./ChartSkeleton";

const useStyles = makeStyles((theme) => ({
    skeleton: {
        backgroundColor: alpha(theme.palette.primary.light, 0.25),
    },
}));

const WidgetSkeleton = () => {
    const classes = useStyles();
    return (
        <Card sx={{ "&": (theme) => ({ ...theme.shape.boxedWrapper, borderRadius: "20px", p: 1, width: "100%" }) }}>
            <CardHeader
                action={
                    <Skeleton animation="wave" variant="circular" width={32} height={32} className={classes.skeleton} />
                }
                title={<Skeleton animation="wave" height={24} width="90%" className={classes.skeleton} />}
                subheader={<Skeleton animation="wave" height={24} width="50%" className={classes.skeleton} />}
                sx={{ pb: 0 }}
            />
            <CardContent>
                <Skeleton animation="wave" height={16} width="100%" sx={{ mb: 0.5 }} className={classes.skeleton} />
                <Skeleton animation="wave" height={16} width="80%" sx={{ mb: 2 }} className={classes.skeleton} />

                <Skeleton animation="wave" height={36} width="20%" sx={{ mb: 0 }} className={classes.skeleton} />
                <Skeleton animation="wave" height={24} width="24%" sx={{ mb: 0 }} className={classes.skeleton} />
            </CardContent>

            <ChartSkeleton />

            <CardContent>
                <Skeleton animation="wave" height={16} width="33%" className={classes.skeleton} />
            </CardContent>
        </Card>
    );
};

export default WidgetSkeleton;
